import { useCallback, useRef } from 'react';
import { TweenMax } from 'gsap';
import ease from 'utils/animations/ease';
import useGsap from './use-gsap-hook';

const fadeInSlide = (axis, translateLength, options = {} ) => {
  const {
    animateChildren,
    triggerRef,
    animateInOptions = {}
  } = options;

  const {
    duration = 1.15,
    stagger = 0.06,
    ...extraOptions
  } = animateInOptions;

  const ref = useRef();
  const getRef = (animRef) => {
    return animateChildren ? animRef.current.childNodes : animRef.current;
  };

  const animateInit = useCallback(() => {
    TweenMax.set(
      getRef(ref),
      {
        opacity: 0.01,
        [axis]: translateLength,
      }
    );
  }, [ref]);

  const animateIn = useCallback(() => {
    TweenMax.staggerTo(
      getRef(ref),
      duration,
      {
        opacity: 1,
        ease: ease.in,
        delay: 0.13,
        ...extraOptions
      },
      stagger,
    );

    TweenMax.staggerTo(
      getRef(ref),
      // The translation is always faster
      duration * 0.5,
      {
        [axis]: 0,
        ease: ease.inOutQuad,
        delay: 0.13,
        ...extraOptions,
      },
      stagger,
    );

  }, [ref]);

  useGsap({ ref: triggerRef || ref, animateIn, animateInit });

  return [ ref, animateIn ];
};

export default fadeInSlide;
