import React from "react";
import Section from "../../components/Section/Section";
import fadeInSlide from 'utils/animations/fade-in-slide';
import { addRedPeriod } from 'utils/helpers';

import "./PageHeader.scss";

const PageHeader = ({ description, heading }) => {
  const [ tweenRef ] = fadeInSlide('x', -25);

  return (
    <Section className="PageHeader">
      <div className="container">
        <div className="row">
          <div ref={tweenRef} className="col-lg-8 alpha0">
            {/* <h3 dangerouslySetInnerHTML={{ __html: addRedPeriod(heading) }} /> */}
            <h3 dangerouslySetInnerHTML={{ __html: heading }} />
            <div className="PageSubDescription">
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default PageHeader;
